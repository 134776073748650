import React, { useEffect } from "react";

import styles from "./About.module.css";
import { getImageUrl } from "../../utils";

export const About = () => {

    useEffect(() => {
        window.addEventListener('scroll', reveal);

        return () => {
            window.removeEventListener('scroll', reveal);
        };
    }, []);

    const reveal = () => {
        var reveals = document.querySelectorAll(`.${styles.reveal}`);

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var revealTop = reveals[i].getBoundingClientRect().top;
            var revealPoint = 150;

            if (revealTop < windowHeight - revealPoint) {
                reveals[i].classList.add(styles.active);
            } else {
                reveals[i].classList.remove(styles.active);
            }
        }
    };

  return <section className={`${styles.container} ${styles.reveal}`} id="about">
                <h2 className={styles.title}>Who Me?!</h2>
                <div className={styles.content}>
                    <img src={getImageUrl("about/aboutImage.jpg")} alt="About Me Img" className={styles.aboutImage} />
                    <ul className={styles.aboutItems}>
                        <li className={styles.aboutItem}>
                            <div className={styles.aboutItemText}>
                                <p>- Proud owner of a beautiful Blenheim Cavalier King Charles called <span className={styles.impText}>Rosie</span>!</p>
                            </div>
                        </li>
                        <li className={styles.aboutItem}>
                            <div className={styles.aboutItemText}>
                                <p>- Part Time <span className={styles.impText}>Aerialist</span> & have trained in the art for around 7 years now!</p>
                            </div>
                        </li>
                        <li className={styles.aboutItem}>
                            <div className={styles.aboutItemText}>
                                <p>- <span className={styles.impText}>Hufflepuff</span> for the win always! Anyone who says otherwise is kidding themselves!</p>
                            </div>
                        </li>
                        <li className={styles.aboutItem}>
                            <div className={styles.aboutItemText}>
                                <p>- Mint Choc Chip, Raspberry Ripple or Biscoff <span className={styles.impText}>Ice Cream</span> can never be beaten!</p>
                            </div>
                        </li>
                    </ul>
                </div>
           </section>
  ;
};
export default About;